import React, { useState } from "react";
import "./layout.css";
import { css } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCircleUser,
  faClose,
  faHouse,
} from "@fortawesome/free-solid-svg-icons";
import { navigate } from "gatsby";
import Menu from "./Menu";
import { StaticImage } from "gatsby-plugin-image";

export default function Header() {
  const [showMenu, _setShowMenu] = useState(false);
  // const bodyElm = document.getElementsByTagName("body")[0];
  function setShowMenu(state) {
    if (state) {
      // bodyElm.classList.add("noScroll");
    } else {
      // bodyElm.classList.remove("noScroll");
    }
    _setShowMenu(state);
  }
  return (
    <div
      css={css`
        background-color: #1e1e1e;
        display: flex;
        justify-content: space-between;
        height: 56px;
        align-items: center;
        position: sticky;
        top: 0;
        z-index: 10;
        @media (min-width: 1024px) {
          justify-content: flex-end;
        }
      `}
    >
      <Menu showMenu={showMenu} setShowMenu={setShowMenu} />
      <div
        name="here"
        css={css`
          display: flex;
          justify-content: flex-start;
          align-items: center;
        `}
      >
        <div
          css={css`
            width: 56px;
            height: 100%;
            justify-content: center;
            display: flex;
            @media (min-width: 1024px) {
              display: none;
            }
          `}
        >
          <FontAwesomeIcon
            icon={faBars}
            size="xl"
            color="#fff"
            onClick={() => setShowMenu(true)}
          />
        </div>
        <p
          css={css`
            font-weight: bold;
            font-size: 20px;
            padding: 0;
            @media (min-width: 1024px) {
              display: none;
            }
          `}
        >
          Hotwife in Northern Arizona
        </p>
      </div>
      <a
        href="#"
        css={css`
          width: 56px;
          justify-content: center;
          display: flex;
          text-decoration: none;
          background-color: #d1d5db;
          width: fit-content;
          border-radius: 6px;
          padding: 6px;
          margin-right: 14px;
          display: none;
        `}
      >
        <FontAwesomeIcon icon={faCircleUser} size="xl" color="#000" />
        <span
          css={css`
            padding-left: 4px;
            color: #000;
          `}
        >
          Sign In
        </span>
      </a>
    </div>
  );
}
