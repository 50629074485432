import React, { useState } from "react";
import "./layout.css";
import { css } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faHouse,
  faAngleDown,
  faVideo,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import { navigate, graphql, useStaticQuery } from "gatsby";

export default function Menu({ showMenu, setShowMenu }) {
  const [showVideos, setShowVideos] = useState(false);
  const { allMarkdownRemark } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
        edges {
          node {
            frontmatter {
              slug
              title
              description
              teaser {
                image {
                  childImageSharp {
                    gatsbyImageData(width: 800)
                  }
                }
              }
              full {
                title
                location
                image {
                  childImageSharp {
                    gatsbyImageData(width: 800)
                  }
                }
                video_url
              }
              free {
                title
                location
                image {
                  childImageSharp {
                    gatsbyImageData(width: 800)
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const content = allMarkdownRemark.edges.reduce((arr, obj) => {
    arr.push(obj.node.frontmatter);
    return arr;
  }, []);

  return (
    <div
      name="drawer"
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #000;
        transform: ${showMenu ? `translateX(0)` : `translateX(-100%)`};
        transition: transform 0.3s;
        background-color: #1e1e1e;
        @media (min-width: 1024px) {
          transform: translateX(0);
          left: 0;
          right: auto;
          bottom: 0;
          top: 0;
          width: 33%;
          z-index: 2;
          border-right: 1px solid #4a4848;
          padding: 14px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 0 14px;
          height: 80px;
          @media (min-width: 1024px) {
            display: none;
          }
        `}
        onClick={() => setShowMenu(false)}
      >
        <FontAwesomeIcon
          icon={faClose}
          color="#fff"
          css={css`
            font-size: 30px;
            margin-right: 14px;
          `}
        />
      </div>
      <p
        css={css`
          font-weight: bold;
          font-size: 20px;
          padding: 0 14px;
          display: none;
          @media (min-width: 1024px) {
            display: flex;
            height: 56px;
            width: 300px;
          }
        `}
      >
        Hotwife in Northern Arizona
      </p>
      <ul
        css={css`
          padding: 20px;
          color: #ffff;
          list-style-type: none;
          font-size: 20px;

          & li {
            line-height: 54px;
          }
          @media (min-width: 1024px) {
            /* margin-top: 56px; */
            width: 300px;
          }
        `}
      >
        <li>
          <a
            href="/"
            css={css`
              text-decoration: none;
              color: #fff;
            `}
            onClick={(e) => {
              e.preventDefault();
              setShowMenu(false);
              navigate("/");
            }}
          >
            <FontAwesomeIcon
              icon={faHouse}
              color="#fff"
              css={css`
                font-size: 30px;
                margin-right: 14px;
              `}
            />
            Home
          </a>
        </li>
        <li onClick={() => setShowVideos(!showVideos)}>
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <FontAwesomeIcon
              icon={faVideo}
              css={css`
                font-size: 30px;
                margin-right: 14px;
              `}
            />
            My Videos
            <FontAwesomeIcon
              icon={faAngleDown}
              css={css`
                margin-left: 14px;
              `}
              rotation={showVideos ? 180 : 0}
            />
          </div>
          <ul
            css={css`
              padding: 20px;
              color: #ffff;
              list-style-type: none;
              font-size: 20px;
              display: ${showVideos ? "block" : "none"};
            `}
          >
            {content.map((data, index) => {
              const { title, description, slug, free, full } = data;
              const shortString =
                free.length > 1
                  ? `${free.length} Short Clips`
                  : free.length === 1
                  ? "1 Short Clip"
                  : "";
              const fullString =
                full.length > 1
                  ? `${full.length} Full Videos`
                  : full.length === 1
                  ? "1 Full Video"
                  : "";
              const combinedString =
                shortString && fullString
                  ? `${shortString} and ${fullString}`
                  : shortString || fullString;

              return (
                <li
                  key={index}
                  css={css`
                    font-size: 16px;
                    line-height: 20px !important;
                    margin-bottom: 24px;
                    border-bottom: 1px dashed #6c6b6b;
                  `}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowMenu(false);
                    navigate(`/cheating/${slug}`);
                  }}
                >
                  <a
                    href={`/cheating/${slug}`}
                    css={css`
                      text-decoration: none;
                      color: #fff;
                    `}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowMenu(false);
                      navigate(`/cheating/${slug}`);
                    }}
                  >
                    {title}
                  </a>
                  <p
                    css={css`
                      font-size: 14px;
                      color: #aeaeae;
                      line-height: 30px;
                    `}
                  >
                    <FontAwesomeIcon
                      icon={faPlay}
                      css={css`
                        margin-right: 14px;
                      `}
                    />
                    {combinedString}
                  </p>
                </li>
              );
            })}
          </ul>
        </li>
      </ul>
    </div>
  );
}
