import React from "react";
import "./layout.css";
import { css } from "@emotion/react";
import Header from "./Header";
export default function Layout({ children }) {
  return (
    <>
      <Header />
      <p
        css={css`
          font-weight: bold;
          font-size: 20px;
          padding: 0 14px;
          @media (min-width: 1024px) {
            display: none;
          }
          display: none;
        `}
      >
        Hotwife in Northern Arizona
      </p>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          padding: 14px;
          @media (min-width: 1024px) {
            /* width: calc(100% - 300px); */
            width: calc(100% - 33%);
            max-width: 630px;
            position: relative;
            left: 33%;
            margin-left: 14px;
            z-index: 10;
          }
        `}
      >
        {children}
      </div>
    </>
  );
}
